import { getExperimentalMode } from "../config";

const loadSearchBar = async (isExperimental: boolean) => {
    if (isExperimental) {
        await import("../experimental/page-builder-host/search-bar")
        //Todo: Remove c.logging once experimental-search is completely rolled-out
        console.log('%cExperimental Search feature ENABLED', 'font-weight: 800; background: #33BB44; color: #fff') 
    } else {
        await import("./search-bar")
        //Todo: Remove c.logging once experimental-search is completely rolled-out
        console.log(`%cExperimental Search feature DISABLED`, 'font-weight: 800; background: #BB3344; color: #fff')
    }
}

getExperimentalMode().then(loadSearchBar)

export {}